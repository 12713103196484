import * as React from "react"
import Layout from "../../../../../components/layout";
import * as styles from "../../../notification.module.css"

const NotificationKanjinumbercrosswordIos20230104Page = () => {
    return (
        <Layout isTop={false}>
            <article>
                <h1>2023年1月4日の「今日の1問」の問題について</h1>
                <p>2023年1月4日の「今日の1問」の問題について、「青息吐息」とすべき箇所が、誤って「青息戸息」となっていました。<br/>
                    以下のリンクから、アプリを最新版に更新してください。</p>
                <a href="https://apps.apple.com/jp/app/%E8%84%B3%E3%83%88%E3%83%AC-%E5%A4%A7%E4%BA%BA%E3%81%AE%E6%BC%A2%E5%AD%97%E3%83%8A%E3%83%B3%E3%82%AF%E3%83%AD/id1527701421">https://apps.apple.com/jp/app/%E8%84%B3%E3%83%88%E3%83%AC-%E5%A4%A7%E4%BA%BA%E3%81%AE%E6%BC%A2%E5%AD%97%E3%83%8A%E3%83%B3%E3%82%AF%E3%83%AD/id1527701421</a>
            </article>
        </Layout>
    )
}

export default NotificationKanjinumbercrosswordIos20230104Page